/* General styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* App container */
.app-container {
  width: 90%;
  max-width: 1400px;
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

/* Title */
.app-title {
  font-size: 28px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Case ID form */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

form input[type="text"] {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-size: 16px;
  color: #333333;
  margin-bottom: 10px;
  outline: none;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

form input[type="text"]:focus {
  border-color: #007bff;
  box-shadow: 0px 0px 4px rgba(0, 123, 255, 0.5);
}

form button {
  padding: 12px 20px;
  background-color: #007bff;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

form button:hover {
  background-color: #0056b3;
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.2);
}

/* Carousel */
.carousel {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  overflow-x: auto;
  padding-bottom: 10px;
  width: 100%;
}

.carousel img {
  width: 100px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.carousel img.selected {
  border: 2px solid #007bff;
  transform: scale(1.1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.carousel img:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Annotation container */
.annotation-container {
  width: 100%;
  max-width: 1080px;
  margin-bottom: 20px;
  border: 2px solid #dddddd;
  border-radius: 10px;
  position: relative;
  background: #f8f8f8;
  overflow: hidden;
}

/* Canvas */
.annotation-canvas {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
}

/* Toolbar */
.toolbar {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.toolbar button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: #007bff;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.toolbar button.active-tool {
  background: #0056b3;
}

.toolbar button:hover {
  background: #0056b3;
}

/* Shape controls */
.shape-controls {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.shape-controls label {
  font-size: 14px;
  color: #333333;
}

.shape-controls input {
  margin-left: 10px;
}

/* Dialog */
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
}

.dialog p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #333333;
}

.dialog button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: #007bff;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin: 0 5px;
}

.dialog button:hover {
  background: #0056b3;
}